const dataRoadmap = [
    {
        id: 1,
        time: '20%',
        list: [
            {
                text: 'Pre-sale'
            },
            {
                text: '2% NFTs withheld for top 100 club partners and Airdrop to random users'
            },
            {
                text: 'Dubai Launch Party'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: '50%',
        list: [
            {
                text: '9 Top cities Reveal and Launch Parties… All members Invited'
            },
            {
                text: 'Member-Exclusive HPS Merch Store gets unlocked, featuring Limited Edition tees, hoodies, and other goodies.'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: '70%',
        list: [
            {
                text: '10 Members Receive Art works of their nft'
            },
            {
                text: 'Top Partner clubs revealed.'
            },
            {
                text: 'Airdrop to Top partners and random users.'
            },
            {
                text: 'More perks Unlocked.'
            }
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: '100%',
        list: [
            {
                text: 'Metaverse Club public launch: A virtual club for hangout and discussion'
            },
            {
                text: "Yearly In person Events in Different Cities"
            }
        ],
        positon: 'right'
    }
]

export default dataRoadmap;