import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import WorkItem from './work-item';

Work.propTypes = {
    data: PropTypes.array,
};

function Work(props) {

    const {data} = props;

    const [dataBlock] = useState({
        subtitle: '',
        title: 'Join the squad',
        desc: `The Horse Power Squad member area will become operational once the sale period is over. It contains details of party locations, access codes, squad VIP passes, and more. It is accessible only to wallets containing at least one Horse Power Squad NFT.
        When you buy a Horse Power Squad NFT, you're not simply buying an avatar or a provably rare piece of art. You are gaining membership access to a squad whose benefits and offerings will increase over time. Your Power Horse can serve as your digital identity and open all nightlife doors in top cities and the metaverse.`
    })
    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title m-b17">{dataBlock.title}</h4>
                            <p className="p-r60">{dataBlock.desc} </p>
                        </div>
                        {data.map((item, index)=>{
                            return (<WorkItem key={index} item={item} />)
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Work;