const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'An NFT is a digital asset that represents real-world objects like art, music, in-game items and videos. They are bought and sold online, frequently with cryptocurrency, and they are generally encoded with the same underlying software as many cryptos.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do I get NFTs?',
        content: 'By connecting your ethereum wallet to this website.',
    },
    {
        id: 3,
        title: 'Are my NFTs unique to only me?',
        content: 'Yes. Your NFT will be one in 10,000',
    },
    {
        id: 4,
        title: 'What does my NFT do?',
        content: 'Along with granting you access to select clubs in  select cities, the horses are also, pretty cool.',
    }
]

export default dataFaq;