import React, { useState } from 'react';
import PageTitle from '../components/pagetitle';
import { initializeApp } from "firebase/app";
import { collection, addDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import countriesQuery from 'countries-code'
import { GlobalContext } from '../customContexts';
import { Link } from 'react-router-dom';


const firebaseConfig = {
    apiKey: "AIzaSyBYkCXhEyDhoVc_9ltpQ0UCXzJtGc0xB1g",
    authDomain: "hpsq-1908c.firebaseapp.com",
    projectId: "hpsq-1908c",
    storageBucket: "hpsq-1908c.appspot.com",
    messagingSenderId: "120879038102",
    appId: "1:120879038102:web:70bcb4e936a7716c144cb7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function LoginPanel(props) {
    const doLogin = (e) => {
        e.preventDefault();
        const email = e.target.email.value
        const password = e.target.password.value

        if (email === "Log1n" && password === "v01D") {
            props.login(true)
        } else {
            alert("Username or password incorrect.");
        }
    }

    return (
        <form method='post' onSubmit={doLogin}>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <input type="text" name="email" placeholder='Enter username/ email' id="username" />
                    <br />&nbsp;
                    <input type="password" name="password" placeholder='Enter password' id="password" />
                    <br />&nbsp;<br />
                    <button className='tf-button-st2 btn-effect' type='submit'>Log in</button>
                </div>
            </div>
        </form>
    )
}

function AddWhiteLister(props) {
    let [address, setAddress]  = useState("");
    let [isLoading, setIsLoading] = useState(false);

    let submitWhitelist = (e) => {
        setIsLoading(true)
        e.preventDefault();
        const dt = new Date()
        const addresses = address.split(",")
        try {
            addresses.forEach(address => {
                const docRef = addDoc(collection(db, "whitelist"), {
                    address: address,
                    date: dt.toISOString()
                });
                docRef.then((id)=>{
                    setIsLoading(false)
                    console.log(`Written address ${address} ID: ${id}`)
                })
            })
          } catch (e) {
            setIsLoading(false)
            console.error("Error adding document: ", e);
          }
    }

    const SubmitButton = (props) => {
        if (isLoading) {
            return <span className="fa fa-spinner fa-spin"></span>
        }
        return <button className="tf-button-st2 btn-effect">Submit</button>
    }

    return (
        <div className='page-nft'>
            <section className="tf-section tf-project home-2 nft-item">
                <form action="#" onSubmit={submitWhitelist} method="POST" className="wl-form">
                <h2>Add white list address</h2>
                    <input type="text" onChange={(e)=>setAddress(e.target.value)} name="contract_address" id="contract_address" placeholder="wallet address(es) eg: 0x5657dsjbnndsn" />
                    <SubmitButton />
                </form>
            </section>
        </div>
    )
}


function AdminPanel(props) {
    const [perks, setPerks] = useState(1);
    const [images, setImages] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    let perksInput = [];
    let imagesInput = [];

    for (let i = 0; i< perks; i++) {
        perksInput.push(
            <span key={`perks-${i}`}>
                <label htmlFor="perk">Enter perk {i+1}</label>
                <input type="text" name="perks" id="perks" placeholder='Perk' required/>
            </span>
        )
    }
    for (let i = 0; i< images; i++) {
        imagesInput.push(
            <span key={`images-${i}`}>
                <label htmlFor="image">Enter Image {i+1}</label>
                <input type="url" name="images" id="images" placeholder='Image Url' required/>
            </span>
        )
    }
    const incrementPerks = (e) => {
        let current = perks
        setPerks(current+1)
    }
    const generateSearchKeyWords = (word) => {
        let out = []
        let all = word.split('')
        all.forEach((letter, index) => {
            let text = ""
            for(let i = index; i<all.length; i++) {
                text += all[i]
                out.push(text)
            }
        })
        return out;
    }

    const submitPartner = (e) => {
        setIsSubmitting(true);
        let single_values = [
            'country',
            'city',
            'partner',
            'category',
            'logo_url',
            'location',
            'twitter',
            'instagram',
            "perks",
            "images",
            "description",
            "tiktok",
            "website",
            'access_details'
        ]
        e.preventDefault();
        const payload = {};
        single_values.forEach(key => {
            let ret_value = []

            try {
                e.target[key].forEach(sub => {
                        ret_value.push(sub.value)
                })
            } catch (error) {
                if (key === "images" || key === "perks") {
                    ret_value.push(e.target[key]?.value)
                } else {
                    ret_value = e.target[key]?.value;
                }
            }
            payload[key] = ret_value
        });

        let date = new Date()
        payload["search_keywords"] = generateSearchKeyWords(e.target["partner"].value.toLowerCase())
        payload['created'] = date.toJSON()

        const docRef = addDoc(collection(db, "partners"), payload);
        docRef.then((id)=>{
            e.target.reset();
            console.log(`Written ID: ${id}`);
            setIsSubmitting(false);
        }).catch(err => {
            setIsSubmitting(false);
            console.log("Error in submission");
        })
    }
    return (
        <div className="container">
            <Link to="/partners" className='fab'>Manage partners</Link><br />
            <AddWhiteLister />
            <hr />
            <h2>Add Partner</h2>
            <div className="alert alert-info">To manage partners already added please click <Link to="/partners"><u>here: Manage partners</u></Link></div>
            <div className="envelope">
                <div className="admin-form">
                    <form action="#" method='post' onSubmit={submitPartner}>
                        <label htmlFor="clubName">Partner Name</label>
                        <input type="text" placeholder='Partner Name' name='partner' required/>
                        <label htmlFor="description">Description</label>
                        <textarea name="description" id="description" cols="30" rows="15" placeholder='description...'></textarea>
                        <label htmlFor="category">Category</label>
                        <br />
                        <select name='category' className='select-input'>
                            <option value="club">Club</option>
                            <option value="restaurant">Restaurant</option>
                            <option value="party">Party</option>
                            <option value="lifestyle">Lifestyle</option>
                        </select>
                        <br />
                        <label htmlFor="logo">Logo</label>
                        <input type="url" name='logo_url' placeholder='logo url' required/>
                        <label htmlFor="country">Country</label>
                        <select name="country" id="country" className='select-input'>
                            {countriesQuery.allCountriesList().map(country=>{
                                return (<option value={country.country_name_en}>{country.country_name_en}</option>)
                            })}
                        </select>
                        <label htmlFor="city">City</label>
                        <input type="text" name='city' placeholder='City' required/>
                        <label htmlFor="location">Address</label>
                        <input type="text" name='location' placeholder='Eg: Duja Tower, Sheikh Zayed Rd, Dubai - United Arab Emirates'/>
                        <label htmlFor="twitter">Twitter</label>
                        <input type="url" name='twitter' placeholder='https://Twitter-url' />
                        <label htmlFor="twitter">Website</label>
                        <input type="url" name='website' placeholder='https://Website-url' />
                        <label htmlFor="tiktok">TikTtok</label>
                        <input type="url" name='tiktok' placeholder='https://Tiktok-url' />
                        <label htmlFor="instagram">Instagram</label>
                        <input type="url" name='instagram' placeholder='https://Instagram-url' />
                        <div className='perks-section'>
                            <b>Perks Section</b><br />
                            {perksInput}
                            <button type='button' onClick={incrementPerks}>Add Perk</button>&nbsp;
                            <button type='button' onClick={()=>{setPerks(perks-1)}}>Remove last</button>
                        </div>
                        <div className='perks-section'>
                            <b>Images</b><br />
                            Add image urls of the partner<br />
                            {imagesInput}
                            <button type='button' onClick={()=>{setImages(images+1)}}>Add Img</button>&nbsp;
                            <button type='button' onClick={()=>{setImages(images-1)}}>Remove last</button>
                        </div>
                        <label htmlFor="access_details">Access Details</label>
                        <input type="text" name='access_details' placeholder='Access details' required/>
                        {isSubmitting ? <span className='fa fa-spinner fa-spin' /> : <button className='tf-button-st2 btn-effect' type="submit">Submit</button>}
                    </form>
                </div>
            </div>
        </div>
    )
}


function AdminPage(props) {
    const [ state, dispatch ] = React.useContext(GlobalContext);
    const loginUser = (e) => {
        dispatch({type: "loggedIn"});
    }
    return (
        <>
            <PageTitle title="Admin" />
            {state.loggedIn ? <AdminPanel /> : <LoginPanel login={loginUser} />}
        </>
    )
}

export default AdminPage;
