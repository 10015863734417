import img1 from '../images/2.png'
import img2 from '../images/3.png'
import img3 from '../images/6.png'
import img4 from '../images/11.png'
import img5 from '../images/15.png'
import img6 from '../images/3.png'


const dataProject = [
    {
        id: 1,
        title: 'Horse Power #X',
        img: img1,
    },
    {
        id: 2,
        title: 'Horse Power #X',
        img: img2,
    },
    {
        id: 3,
        title: 'Horse Power #X',
        img: img3,
    },
    {
        id: 4,
        title: 'Horse Power #X',
        img: img4,
    },
    {
        id: 5,
        title: 'Horse Power #X',
        img: img5,
    },
    {
        id: 6,
        title: 'Horse Power #X',
        img: img6,
    }
]

export default dataProject;