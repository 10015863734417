import {ethers} from 'ethers';
import {useState} from 'react';
import Button from './button'
import NFT from "../nft.json";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { nftContractAddress } from '../utils';

import { initializeApp } from "firebase/app";
import { collection } from "firebase/firestore";
import { getFirestore, getDocs } from "firebase/firestore";
import {checkIfWalletIsConnected, checkCorrectNetwork, connectWallet} from '../features/utils'

const firebaseConfig = {
    apiKey: "AIzaSyBYkCXhEyDhoVc_9ltpQ0UCXzJtGc0xB1g",
    authDomain: "hpsq-1908c.firebaseapp.com",
    projectId: "hpsq-1908c",
    storageBucket: "hpsq-1908c.appspot.com",
    messagingSenderId: "120879038102",
    appId: "1:120879038102:web:70bcb4e936a7716c144cb7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



const MySwal = withReactContent(Swal)


const approvedAddresses = async () => {
    let all = [];
    const querySnapshot = await getDocs(collection(db, "whitelist"));
    querySnapshot.forEach((doc) => {
        all.push(doc.data().address)
    });
    return all;
}


const isWhiteListed = async (address) => {
    const approved = await approvedAddresses();
    return approved.indexOf(address) > -1
}

function MintButton(props) {
    const [loadingState, setLoadingState] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading...")

    const mintHorse = async (e) => {
        const isConnected = await checkIfWalletIsConnected()

        const showError = (error) => {
            const error_msg_map = {
                "INSUFFICIENT_FUNDS": "You do not have enough ethereum to mint. Make sure you have at least 0.23 ethereum balance.",
                "4001": "You cancelled the transaction.",
                "UNSUPPORTED_OPERATION": "You have not properly connected your wallet, please try again.",
                "no_meta_mask": "Please check if you have the metamask plugin installed."
            }
            MySwal.fire({
                title: "Error minting horse",
                text: error_msg_map[error.code+""] || "",
                icon: "error",
                timer: 40000
            }).then(e => {
                setLoadingState(false)
            })
        }

        setLoadingState(true)
        e.preventDefault()

        if (!isConnected) {
            if (! await checkCorrectNetwork()) {
                MySwal.fire({
                    title: "Not connected to the correct chain.",
                    html: `Please connect to ethereum Mainnet to Mint a horse.<br /> To do this, open your 
                    metamask extension and change your network to ethereum. <br /> Still unsure how to do this?
                    <a href='https://metaschool.so/articles/how-to-change-add-new-network-metamask-wallet/'><b>read this article </b></a>`
                })
                return;
            }
            const connected = await connectWallet()
            if(connected !== true) {
                showError({"code": connected})
                return;
            }
        }
        // const wt = await isWhiteListed(window.localStorage.getItem("current_account"));

        // if (!wt) {
        //     MySwal.fire({
        //         title: "Join our whitelist on <a href='https://discord.gg/nnYsQC3ttS' style='color:teal'>Discord</a> to mint.",
        //         text: "",
        //         icon: "info",
        //         footer: ''
        //     })
        //     setLoadingState(false)
        //     return;
        // }
        const confirmation = await MySwal.fire({
            title: "Number of Horses to mint.",
            input: 'number',
            confirmButtonText: 'Mint Horse(s)',
            showCancelButton: true
        })

        if(confirmation.isDenied || confirmation.isDismissed || confirmation.value === '') {
            setLoadingState(false)
            return;
        }

        if(parseInt(confirmation.value) > 10) {
            MySwal.fire({
                title: "Error minting horse",
                text: "Cannot mint more than 10 horses at a single time",
                icon: "error",
            })
            setLoadingState(false)
            return;
        }
        try {
            const { ethereum } = window

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum)
                const signer = provider.getSigner()
                const nftCost = 0.225
                const numberToMint = parseInt(confirmation.value)
                const mintPrice = ethers.utils.parseUnits(nftCost*numberToMint+"", "ether")
                const nftContract = new ethers.Contract(
                    nftContractAddress,
                    NFT.abi,
                    signer
                )

                const estimation = await nftContract.estimateGas.mintHorse(numberToMint, {
                    value: mintPrice,
                    gasLimit: 230520500000
                });

                let nftTx = await nftContract.mintHorse(numberToMint, {
                    value: mintPrice,
                    gasLimit: estimation.add(1000).toNumber()
                })
                console.log('Mining....', nftTx.hash, estimation)
                setLoadingText("Minting...")

                let tx = await nftTx.wait()
                let event = tx.events[0]
                let value = event.args[2]
                let tokenId = value.toNumber()

                MySwal.fire({
                    title: "Successfully Minted",
                    text: `Mined, see your NFT here: https://opensea.io/assets/ethereum/${nftContractAddress}/${tokenId}`,
                    icon: "success",
                })
                setLoadingState(false)
            } else {
                setLoadingState(false);
                console.log("Ethereum object doesn't exist!")
            }
        } catch (error) {
            setLoadingState(false)
            console.log('Error minting character', error)
            showError(error)
        }
    }

    return (
        <>
            {loadingState ? <div><span className='fa fa-spinner fa-spin'></span> {loadingText}</div> : <Button title={props.title || "Mint Horse"} onClick={mintHorse} path='#' /> }
        </>
    )
}

export default MintButton;