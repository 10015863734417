import {deletePartner} from '../../../services';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { uuidv4 } from '@firebase/util';
import { GlobalContext } from '../../../customContexts';
import { alk, nftContractAddress } from '../../../utils';
import axios from 'axios';
import {Buffer} from 'buffer';
import { checkCorrectNetwork, checkIfWalletIsConnected, getCurrentAccount } from '../../../features/utils';
import { MySwal } from '../../../components/alert';
import PartnerSocials from './PartnerSocials';

const PartnerItem = (props) => {
    const {data} = props;
    const [isVisible, setIsVisible] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [ state, dispatch ] = React.useContext(GlobalContext);
    const [loadingState, setLoadingState] = useState(true);

    const deleteItem = () => {
        let conf = window.confirm("sure to delete?")
        if (conf) {

            setIsDeleting(true);
            deletePartner(data).then(e => {
                setIsVisible(false);
            })
        }
    }
    const Footer = (props) => {
        return (
            <>
                {(isDeleting) ? <span className='fa fa-spinner fa-spin'></span> : <button className='btn btn-danger mt-3' onClick={deleteItem}>Delete</button>}
                <button className='btn btn-info mx-2 mt-3'>Edit</button>
            </>
        )
    }
    const checkHasNFT = async () => {
        const account = getCurrentAccount();
        const alchemy_key = Buffer.from(alk, "base64");
        let response;
        try{
            response = await axios.get(
                `https://eth-mainnet.g.alchemy.com/nft/v2/${alchemy_key}/isHolderOfCollection?wallet=${account}&contractAddress=${nftContractAddress}`
                );
        } catch(e) {
            response = false
        }
        return response
    }
    const loadPartnerDetail = () => {
        checkCorrectNetwork().then(e => {
            if (!e) {
                setLoadingState(false)
                MySwal.fire({
                    title: "Error in network",
                    text: "Please ensure you are connected to the ethereum network",
                    icon: "error",
                    timer: 40000
                });
            } else {
                checkIfWalletIsConnected().then(e => {
                    if (e === true) {
                        checkHasNFT().then(e => {
                            setLoadingState(false);
                            dispatch({type: "hasNFT", payload: e.data["isHolderOfCollection"]})
                        });
                    } else {
                        setLoadingState(false);
                    }
                })
            }
        });
        dispatch({type: "currentPartner", payload: data});
        dispatch({type: "partnerDetailsModalState", payload: true});

    }
    return isVisible && (
        <div key={`${data.id}-${uuidv4}`} className="post inner-post" data-aos="fade-up" data-aos-duration="800">
            <div className="image">
                <img src={data.images[0]} alt={data.partner} className="partner-img" />
                <div className="tag">{data.category}</div>
            </div>
            <div className="content-post">
                <PartnerSocials data={data} />
                <div className="title h7"><Link to="#" onClick={loadPartnerDetail}>{data.partner}</Link></div>
                <p>{data.description.substring(0, 400)}</p>
                <Link to="#" onClick={loadPartnerDetail} className="read-more">More</Link><br />
                {state.loggedIn && <Footer />}
            </div>
        </div>
    )
}

export default PartnerItem