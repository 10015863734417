const setCurrentAccount = (account) => {
    window.localStorage.setItem("current_account", account)
}

const getCurrentAccount = () => {
    return window.localStorage.getItem("current_account");
}

const handleEthereum = () => {
    console.log("Ethereum detected")
}

const ensureMetaMask = async () => {
    if (!window.ethereum) {
        window.addEventListener('ethereum#initialized', handleEthereum, {
          once: true,
        });
        // If the event is not dispatched by the end of the timeout,
        // the user probably doesn't have MetaMask installed.
        await setTimeout(handleEthereum, 3000); // 3 seconds
      }
}

const checkCorrectNetwork = async () => {
    ensureMetaMask();
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    console.log('Connected to chain:' + chainId)

    const ethChainId = '0x1'

    if (chainId !== ethChainId) {
        return false
    }
    return true
}

const connectWallet = async () => {
    ensureMetaMask();

    try {
        const { ethereum } = window

        if (!ethereum) {
            console.log('Metamask not detected')
            return false
        }

        if (!await checkCorrectNetwork()) {
            return "CHAIN_ERROR"
        }

        const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

        setCurrentAccount(accounts[0])
        return true
    } catch (error) {
        return error.code
    }
}

const checkIfWalletIsConnected = async () => {
    ensureMetaMask();
    const { ethereum } = window
    if (ethereum) {
        console.log('Got the ethereum obejct: ', ethereum)
    } else {
        return false
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' })

    if (accounts.length !== 0) {
        setCurrentAccount(accounts[0])
        return true;
    } else {
        console.log('No authorized account found')
        return  false;
    }
}


export {setCurrentAccount, connectWallet, checkCorrectNetwork, checkIfWalletIsConnected, getCurrentAccount}
