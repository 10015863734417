import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../components/button';
import { checkCorrectNetwork, checkIfWalletIsConnected, connectWallet, getCurrentAccount } from '../features/utils';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { alk, nftContractAddress } from '../utils';
import axios from 'axios';
import MintButton from '../components/mintButton';
import {Buffer} from 'buffer';


const MySwal = withReactContent(Swal);


function PartnerDetails(props) {
    const [hasNFT, setHasNFT] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [loadingState, setLoadingState] = useState(true);

    const checkHasNFT = async () => {
        console.log("checking for NFT...")
        const account = getCurrentAccount();
        const alchemy_key = Buffer.from(alk, "base64");
        let response;
        try{

            response = await axios.get(
                `https://eth-mainnet.g.alchemy.com/nft/v2/${alchemy_key}/isHolderOfCollection?wallet=${account}&contractAddress=${nftContractAddress}`
                );
        } catch(e) {
            response = false
        }
        return response
    }

    useEffect(() => {
        checkCorrectNetwork().then(e => {
            if (!e) {
                setLoadingState(false)
                MySwal.fire({
                    title: "Error in network",
                    text: "Please ensure you are connected to the ethereum network",
                    icon: "error",
                    timer: 40000
                });
            } else {
                checkIfWalletIsConnected().then(e => {
                    setIsConnected(e);
                    if (e === true) {
                        checkHasNFT().then(e => {
                            setLoadingState(false);
                            setHasNFT(e.data["isHolderOfCollection"])
                        });
                    } else {
                        setLoadingState(false);
                    }
                })
            }
        })
    }, []);

    const connectAndRefresh = async () => {
        const connected = await connectWallet();
        if (connected === true) {
            setIsConnected(true)
        } else {
            MySwal.fire({
                title: "Error.",
                text: "Please ensure you have metamask installed and try again.",
                icon: "error",
                timer: 40000
            });
        }
    }
    const NotConnectedComponent = (props) => {
        return (<section className="page-title p404">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                            <div className="breadcrumbs center" data-aos="fade-up" data-aos-duration="800">
                                {(loadingState) && <span className='fa fa-spin fa-spinner'></span>}
                                <h2>Restricted page</h2>
                                <p>Only users with the HPS Token can view this page. <br />Please connect the right wallet to the ethereum network and mint a horse to gain access.</p>
                                {isConnected ? <MintButton title='🐴 Mint your own horse' /> : <Button title='🦊 Connect Wallet' onClick={(e) => {connectAndRefresh()}} path='#' />}
                            </div>
                    </div>
                </div>
            </div>
        </section>)
    }

    const ConnectedComponent = (props) => {
        <div></div>
    }

    return (
        <>
         {hasNFT ? <ConnectedComponent /> : <NotConnectedComponent />}
        </>
    );
}

export default PartnerDetails;