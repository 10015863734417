import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PageTitle from '../components/pagetitle';
import '../scss/components/form.scss'
import { useEffect } from 'react';
import {filterPartners, filterTypes} from '../services';
import { Modal } from 'react-bootstrap';
import countriesQuery from 'countries-code'

import { MySwal } from '../components/alert';
import PartnerItem from "./components/partners/PartnerItem";
import PartnerDetailsModal from './components/partners/PartnerDetailsModal';


function Partner(props) {

    const [partners, setPartners] = useState([]);
    const [isPartnersLoading, setIsPartnersLoading] = useState(true);
    const [categoryFilter, setCategoryFilter] = useState("");
    const [isLastPage, setIsLastPage] = useState(false);
    const pageSize = 12;
    const [lastIndex, setLastIndex] = useState(0);
    const [currentFilterType, setCurrentFilterType] = useState("all");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [locationType, setLocationType] = useState("--");
    const [locationKeyWord, setLocationKeyword] = useState("");
    const locationFilterMap = {
        "city": filterTypes.city,
        "country": filterTypes.country,
        "state": filterTypes.state,
        "address": filterTypes.address,
    }

    const hideFilterModal = () => {
        setShowFilter(false);
    }

    useEffect(() => {
        filterPartners("all", "", lastIndex, pageSize).then(e=>{
            setPartners(e)
            if (e.length < pageSize) {
                setIsLastPage(true)
            } else {
                setLastIndex(e[e.length-1]?.created)
            }
            setIsPartnersLoading(false)
        })
    }, [])

    const setFilter = (type, keyword)  => {
        setCurrentFilterType(type);
        setIsPartnersLoading(true);
        setLastIndex(0);
        if(type === filterTypes.category) {
            setCategoryFilter(keyword)
        }
        filterPartners(type, keyword, lastIndex, pageSize).then(e => {
            setPartners(e)
            setShowFilter(false)
            setLastIndex(e[e.length-1]?.created)
            setIsPartnersLoading(false)
        })
    }
    const search = (e) => {
        const keyword = e.target[0].value;
        setSearchKeyword(keyword)
        setFilter(filterTypes.search, keyword)
        e.preventDefault();
    }

    const setLocationFilter = (e) => {
        setLocationType(e.target.value);
    }

    const filterLocations = () => {
        const locationKeywordParsed = locationKeyWord?.target?.value
        if(locationKeywordParsed) {
            setFilter(locationFilterMap[locationType], locationKeywordParsed)
        }
    }

    const loadNextPage = () => {
        setIsPartnersLoading(true)
        const getFilter = () => {
            if(currentFilterType === filterTypes.search) {
                return searchKeyword;
            }
            if(currentFilterType === filterTypes.category) {
                return categoryFilter;
            }
            if(currentFilterType in locationFilterMap) {
                return locationKeyWord?.target?.value;
            }
            return "";
        }

        filterPartners(currentFilterType, getFilter(), lastIndex, pageSize).then(e=>{
            if (e.length < 1) {
                setIsLastPage(true)
                MySwal.fire({
                    title: "Last page",
                    text: "No more items to load",
                    icon: "info",
                    timer: 1500
                })
            } else {
                setPartners(partners.concat(e))
                setLastIndex(e[e.length-1]?.created)
            }
            setIsPartnersLoading(false)
        })
    }

    return (
        <>
        <PageTitle title="Partners" />
        <Modal show={showFilter} onHide={hideFilterModal}>
            <Modal.Header closeButton>Filter Partners</Modal.Header>
            <div>
                {locationType === "country" &&
                <select className='select-input' onChange={setLocationKeyword} name="country" id="country">
                    <option value="">Select Country</option>
                    {countriesQuery.allCountriesList().map(country=>{
                        return (<option key={country.country_name_en} value={country.country_name_en}>{country.country_name_en}</option>)
                    })}
                </select>
                }
                {locationType !== "country" && locationType !== "--" &&
                <input type="search" onChange={setLocationKeyword} name="state" id="state" placeholder={locationType + " name"} />}

                <select className='select-input' onChange={setLocationFilter} name="country" id="country">
                    <option value="--">Filter by</option>
                    <option value="country">Country</option>
                    <option value="state">State</option>
                    <option value="city">City</option>
                    <option value="address">Address</option>
                </select>

                <button className='tf-button-st2 btn-effect' onClick={filterLocations}><span className='fa fa-search'></span> Filter</button>
            </div>
        </Modal>

        <Modal>
        <Modal.Header closeButton>Edit Partner</Modal.Header>
        </Modal>
        <section className="tf-section tf-project home-2 nft-item">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="widget widget-search" data-aos="fade-in" data-aos-duration="800">
                            <form action="#" method='get' onSubmit={search}>
                                <input type="text" name='search' placeholder="Search" required="" />
                                <button><i className="fal fa-search"></i></button>
                            </form>
                        </div>
                    </div>

                    <div className="col-md-8 z-index">
                        <div className="select-box" data-aos="fade-in" data-aos-duration="800">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    {categoryFilter !== "" ? categoryFilter : "All Categories"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <li data-toggle="modal" onClick={()=>{setFilter(filterTypes.category, "club")}} data-target="#delete_client">Club</li>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                        <li data-toggle="modal" onClick={()=>{setFilter(filterTypes.category, "restaurant")}} data-target="#edit_client">Restaurant</li>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                        <li data-toggle="modal" onClick={()=>{setFilter(filterTypes.category, "party")}} data-target="#edit_client">Party</li>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                        <li data-toggle="modal" onClick={()=>{setFilter(filterTypes.category, "lifestyle")}} data-target="#edit_client">Lifestyle</li>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown onClick={()=>{setShowFilter(true)}}>
                                <Dropdown.Toggle id="dropdown-basic">
                                Filter Location
                                </Dropdown.Toggle>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div>
                    Partner list
                    {isPartnersLoading && <div className='pt-3'><span className='fa fa-spinner fa-spin fa-2x'></span> loading...</div>}
                    {partners.length === 0 && !isPartnersLoading && <div>No partners to show at the moment</div>}
                    {partners.map((partner)=>{ return <PartnerItem key={partner.id} data={partner} />})}
                </div>
                {isPartnersLoading && (<button className='load-more-btn'><span className='fa fa-spinner fa-spin'></span></button>)}
                {!isPartnersLoading && !isLastPage && (<button className='load-more-btn' onClick={loadNextPage}>Load More</button>)}
            </div>

            <PartnerDetailsModal />
        </section>
        </>
    );
}

export default Partner
