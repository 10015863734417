import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import AboutItem from './about-item';
import MintButton from '../../components/mintButton';


About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'What is HorsePower Squad?',
            desc : `
            Horse Power Squad is a collection of 10,000 NFTs - a unique digital collectible on the Ethereum blockchain. Your Horse Power Squad NFT also doubles as your Squad VIP membership access that grants access to members-only benefits,  which include VIP access to all luxury clubs, parties, and events in the world's top cities. Future benefits will be announced once milestones are accomplished. Our last milestone is a Yearly in-person event in different cities and a Virtual club in the metaverse.
            `
        }
    )

    return (
        <section className="tf-section tf-about" id='about-section'>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                {/* <p className="h8 sub-title">{dataBlock.subtitle}</p> */}
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>

                            <MintButton title='🐴 Mint your own horse' />
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;