const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    // {
    //     id: 2,
    //     name: 'About',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'About 01',
    //             links: '/about-v1'
    //         },
    //         {
    //             id: 2,
    //             sub: 'About 02',
    //             links: '/about-v2'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Coming Soon',
    //             links: '/coming-soon'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Community',
    //             links: '/community'
    //         },
    //         {
    //             id: 5,
    //             sub: 'Team Details',
    //             links: '/team-details'
    //         },
    //         {
    //             id: 6,
    //             sub: 'FAQ',
    //             links: '/faq'
    //         },
    //         {
    //             id: 7,
    //             sub: '404',
    //             links: '/404'
    //         },

    //     ]
    // },
    {
        id: 3,
        name: 'About Us',
        links: '#about-section'
    },
    {
        id: 4,
        name: 'Collection',
        links: '#collection'
    },
    {
        id: 5,
        name: 'Road Map',
        links: '/road-map',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Road Map 01',
        //         links: '/road-map-v1'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Road Map 02',
        //         links: '/road-map-v2'
        //     },
        // ]
    },
    {
        id: 2,
        name: 'Buy a horse',
        links: '#buy-a-horse'
    },
    // {
    //     id: 4,
    //     name: 'Nft',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Nft Item',
    //             links: '/nft-item'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Nft Item Details',
    //             links: '/nft-item-details'
    //         },
    //     ],
    // },
    // {
    //     id: 5,
    //     name: 'Blog',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Our Blog',
    //             links: '/blog'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Details',
    //             links: '/blog-details'
    //         },
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Contact',
    //     links: '/contact'
    // },
]

export default menus;