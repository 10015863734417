import { Modal } from 'react-bootstrap';
import MintButton from '../../../components/mintButton';
import Button from '../../../components/button';
import { GlobalContext } from '../../../customContexts';
import React from 'react';
import { MySwal } from '../../../components/alert';
import { connectWallet } from '../../../features/utils';
import { useState, useEffect } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import PartnerSocials from './PartnerSocials';


const PartnerDetailsModal = (props) => {
    // const {show} = props
    const [state, dispatch] = React.useContext(GlobalContext)
    const [isConnected, setIsConnected] = useState(false);
    const [show, setShowModal] = useState(false)

    const hideModal = () => {
        dispatch({type:"partnerDetailsModalState", payload: false})
    }
    const connectAndRefresh = async () => {
        const connected = await connectWallet();
        if (connected === true) {
            setIsConnected(true)
        } else {
            MySwal.fire({
                title: "Error.",
                text: "Please ensure you have metamask installed and try again.",
                icon: "error",
                timer: 40000
            });
        }
    }

    useEffect(() => {setShowModal(state.partnerDetailsModal)}, [state])

    return (
    <Modal
        size='lg'
        show={show}
        onHide={hideModal}
    >
        <Modal.Header closeButton>{state.currentPartner?.partner}</Modal.Header>
            <div className="modal-body center">
                <div>
                {/* {(loadingState) && <span className='fa fa-spin fa-spinner'></span>} */}
                <SimpleImageSlider
                    width="100%"
                    height={504}
                    images={state.currentPartner?.images}
                    showBullets={true}
                    showNavs={true}
                    autoPlay={true}
                />
                </div>
                <div className="p-2 content-post-modal">
                    <h3>{state.currentPartner?.partner}</h3>
                    <PartnerSocials data={state.currentPartner} />
                    <div className='mb-5'>{state.currentPartner?.description}</div>
                    <h4>Perks</h4>
                    <ul>
                        {state.currentPartner?.perks && state.currentPartner?.perks.map((perk, id)=>(<li key={id}>perk</li>))}
                    </ul>
                    <h4>Location</h4>
                    <div>{state.currentPartner?.location} {state.currentPartner?.city}, {state.currentPartner?.country}</div>

                    {state.hasNFT ? <div>
                        <h4>Access Details</h4>
                        {state.currentPartner?.access_details}
                        </div> : <><hr /><span>Connect wallet and mint a horse to view access details</span></>}
                </div>
            </div>
        {!state.hasNFT && <Modal.Footer>{isConnected ? <MintButton title='🐴 Mint your own horse' /> : <Button title='🦊 Connect Wallet' onClick={(e) => {connectAndRefresh()}} path='#' />}</Modal.Footer>}
    </Modal>)
}

export default PartnerDetailsModal
