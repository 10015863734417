import queen from '../images/queen.png';
import img3 from '../images/6.png'
import leader from '../images/leader.png'


const dataTeam = [
    {
        id: 1,
        img: img3,
        name: 'Squad King',
        position: '',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-instagram',
                link: 'https://www.instagram.com/mkhaldi55/'
            },
        ]
    },
    {
        id: 2,
        img: queen,
        name: 'Squad Queen',
        position: '',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-instagram',
                link: 'https://www.instagram.com/micajova_/'
            },
        ]
    },
    {
        id: 1,
        img: leader,
        name: 'Squad Leader',
        position: '',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/drdonmoses'
            },
        ]
    },
]

export default dataTeam;