import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, limit, orderBy, doc, deleteDoc, startAfter, updateDoc } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBYkCXhEyDhoVc_9ltpQ0UCXzJtGc0xB1g",
    authDomain: "hpsq-1908c.firebaseapp.com",
    projectId: "hpsq-1908c",
    storageBucket: "hpsq-1908c.appspot.com",
    messagingSenderId: "120879038102",
    appId: "1:120879038102:web:70bcb4e936a7716c144cb7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const buildResponse = (querySnapshot) => {
    let allPartners = [];
    querySnapshot.forEach(a=> {
        let data = a.data()
        data["id"] = a.id
        allPartners.push(data)
    })
    return allPartners;
}

export const filterTypes = {
    search: "search",
    category: "categoryFilter",
    city: "city",
    country: "country",
    state: "state",
    address: "address",
}


export const filterPartners = async (type, keyword, start=0, length=10) => {
    const partnersRef = collection(db, "partners");
    let querySnapshot;

    switch (type) {
        case filterTypes.search:
            querySnapshot = await getDocs(
                query(
                    partnersRef,
                    where("search_keywords", "array-contains", keyword.toLowerCase()),
                    orderBy('created'),
                    startAfter(start),
                    limit(length),
                )
            )
            break;
        case filterTypes.category:
            querySnapshot = await getDocs(
                query(
                    partnersRef,
                    where("category", "==", keyword.toLowerCase()),
                    orderBy('created'),
                    startAfter(start),
                    limit(length)
                )
            )
            break;
        // TODO: rework entire block. This can be simpler
        case filterTypes.country:
            console.log(type, keyword, start, length)
            querySnapshot = await getDocs(
                query(
                    partnersRef,
                    where("country", ">=", keyword),
                    orderBy('country'),
                    orderBy('created'),
                    startAfter(start),
                    limit(length)
                )
            )
            break;
        case filterTypes.city:
        case filterTypes.state:
        case filterTypes.address:
                console.log(type, keyword, start, length)
                querySnapshot = await getDocs(
                    query(
                        partnersRef,
                        where(type, ">=", keyword.toLowerCase()),
                        orderBy(type),
                        orderBy('created'),
                        startAfter(start),
                        limit(length)
                    )
                )
                break;
        default:
            querySnapshot = await getDocs(
                query(
                    collection(db, "partners"),
                    orderBy("created"),
                    startAfter(start),
                    limit(length)
                )
            );
            break;
    }
    return buildResponse(querySnapshot);
}

export const deletePartner = async (data) => {
    return deleteDoc(doc(db, "partners", data.id))
}


export const updatePartner = async (id, data) => {
    return updateDoc(doc(db, "partners", id), data)
}
