const dataAbout = [
    {
        id: 1,
        title: 'Fair Distribution',
        desc: 'There are no bonding curves, buying a Horse Power Squad NFT costs 0.22 ETH. There are no price tiers, HPS membership costs are the same for everyone.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Buy a horse',
        desc: 'Connect your wallet and mint your very own unique HPS item.',
    },
    {
        id: 3,
        title: 'Usage rights included',
        desc: 'Each Horse Power Squad NFT is unique and programmatically generated from over 101 possible traits, including expression, eyes, headwear, clothing, accessories, and more. All horses are cool, but some are rarer than others.',
    },
]

export default dataAbout;