const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Connect wallet',
        text : 'Tokens can be bought with eth only.',
    },
    {
        id: 2,
        numb: '02',
        title: 'Buy your horse',
        text : 'Buy as many horses as you like. Note each horse is unique.'
    },
    {
        id: 3,
        numb: '03',
        title: "View collection on opensea",
        text : 'You can view the collection on Opensea and see how your horse stacks up to the others.'
    },
    {
        id: 4,
        numb: '04',
        title: "Sell to others",
        text : 'Buy or trade horses with other members to enjoy horse specific perks.'
    },
]
export default dataWork;