export const reducer = (state, action) => {
    switch (action.type) {
      case "loggedIn":
        return {
          ...state,
          loggedIn: true
        }
      case "currentPartner":
        return {
            ...state,
            currentPartner: action.payload
        }
      case "partnerDetailsModalState":
        return {
            ...state,
            partnerDetailsModal: action.payload
        }
      case "hasNFT":
        return {
          ...state,
          hasNFT: action.payload
        }
      default:
        return state
    }
}

export const initialState = {
    loggedIn: false
}
